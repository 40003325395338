import {reactive} from "vue";
import AbstractClass from '@/mixins/abstractClass'
import FormClass from "@/mixins/formClass"
import {onBeforeRouteLeave} from "vue-router";

class Form extends FormClass {
  constructor(table = null) {
    super(table)
  }
  /** Alias name */
  name_alias = null
  /** Key name for some requirement */
  key_name = null
  /** Type of Enum (text) */
  type = null
  /** ID of type enum */
  type_id = null
  /** Global Filter status */
  is_filter = false
}

class EnumerateClass extends AbstractClass {
  constructor(table = null, config = {}) {
    super(table, config);

    this.__f = Form

    this.lm = 500
    super.resetForm()
  }
}

//  Class configure
const config = {
  //  Router
  r: {m: 'my-enumerate-manage'},
  //  Set reload true after call submit()
  rl: true,
}
//  Begin set Object
let en = reactive(new EnumerateClass('Enumerate', config))

const useEnumerateMixins = () => {

  return {
    en
  }
}

export default useEnumerateMixins
