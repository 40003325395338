import ajax from "@/ajax";

export const absState = {
  /** Model name of tb (User, News, Events...) */
  tb: null,
  url: null,
  //  Router config
  r: {
    /** @var c Create page */
    c: null,
    /** @var u Update page */
    u: null,
    /** @var m Management page */
    m: null,
    /** @var r Restore page */
    r: null,
    /** @var d View detail an object */
    d: null,
    /** @var v View detail with report an object */
    v: null,
  },
  //  Main configuration
  cf: {
    //  Form config
    f: {}
  },
  /** Inner f */
  __f: null,

  timeOut: 1500, // millisecond
  /** ld status */
  ld: false,
  /** Reload status */
  rl: false,
  rf: false,
  /** l return from api */
  data: [],
  search: [],
  l: {data: [], search: []},
  /** Single item object */
  i: {},
  /** Number of display limited per page */
  lm: 30,
  /** Current page */
  p: 1,
  /** Keywords to search */
  q: null,
  fq: null,  //  Filter query:> Sử dụng phương thức filter query này không có tính mở rộng trong tương lai:> cần điều chỉnh
  /**
   /** Category ID & Event ID to filter */
  filter: [],
  categoryId: '',
  eventId: '',
  storagesId: '',
  enumerateId: '',
  //  Limit options list
  lmOption: [
    {value: 10, text: '10'},
    {value: 30, text: '30'},
    {value: 50, text: '50'},
    {value: 100, text: '100'},
    {value: 200, text: '200'},
  ],
  /** Type to filter */
  type: '',
  /** Total item on database */
  tt: 0,
  /** Form object default */
  f: {},
  /** Column width of table */
  w: {
    //  Fixed width
    main: 0, pw: 40, img: 50, sl: 30 /** Select box */,
    f: 70,  //  Featured
    p: 90,  //  Priority
    l: 120, //  Language
    t: 150, //  Type name | Category name
    // Operation
    /** 1 icon: 55 */ op: 110,
  },
  //  Vue Router instance object (vue-router)
  router: null,
  /** @var isExport - allow Export button */
  isExport: false,
  /** @var action - action selection for multiple update */
  action: [],
  /** list of selected - used for multi delete or update */
  multiSelect: [],
  /** Mass update object */
  mu: {
    col: null,  //  Column to update
    value: null,  //  Value to update
    selected_id: [], //  List selected Id will be update
    type: null, //  Type of value to update (boolean, numeric, string, json, date, dateTime)
  },
  /** Export config */
  ep: {
    header: [],
    bgColor: '4ADE80',
    name: null,
    type: 'xlsx', //(xlsx || csv)
  },
  ip: {
    data: []
  },
  /** Todo: join modal to one object */
  /** @var modal - form modal open status */
  modal: false,
  /** @var expModal - Export modal open status */
  expModal: false,
  /** @var quickModal - Multiple update modal open status */
  multiModal: false,

  set: (table = null, config = {}) => {
    absState.tb = table
    absState.url = table.toLowerCase()
  }
}

export const action = {
  gets({commit}) {
    console.log(absState)
    absState.ld = true
    ajax.get(absState.url).then(res => {
      if (!res.data.error) {
        commit('setData', res.data.data)
      }
      absState.ld = false
    }).catch(err => {
      absState.ld = false
    })
  }
}

export const mutation = {
  setData(state, payload = {}) {
    absState.data = payload.data ? payload.data : payload
  }
}
