import {createStore} from "vuex";
const debug = process.env.NODE_ENV !== 'production'
import plugins from "@/vuex/plugins";

// for testing
if (process.env.NODE_ENV !== 'production') {
  window.localStorage.clear()
}

//  Import modules
import species from '@/vuex/modules/species'
import enumerate from '@/vuex/modules/enumerate'



export default createStore({
  modules: {
    species,
    enumerate,
  },
  strict: debug,
  plugins
})
