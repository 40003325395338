import { createApp, nextTick } from 'vue'
import App from './App.vue'

const app = createApp(App)

/** Import Library */
import ISpaElement from 'ispa-element'
import ElementPlus from 'element-plus'
import {router, i18n} from '@/config/route.config'
/** Import plugins css */
import 'element-plus/lib/theme-chalk/index.css'
import 'nprogress/nprogress.css'
import '@/css/style.css'
import '@/css/tooltip.css'
import '@vueform/multiselect/themes/default.css'
import {baseTitle} from "@/config/base";

import store from './vuex'

app.use(ISpaElement)
  .use(ElementPlus)
  .use(router).use(i18n)
  .use(store)

router.isReady().then(() => {
  router.afterEach(async route => {
    await nextTick()
    document.title = (route.meta.lang ? i18n.global.t(route.meta.lang) + ' - ' : (route.meta.title ? route.meta.title + ' - ' : '')) + baseTitle
  })
})

/** Add global component */
app.component('no-permission-error', require('./components/no-permission-error.vue').default)
app.component('i-account-info-box', require('./components/account-info-box.vue').default)
app.component('v-quill', require('./components/v-quill.vue').default)
app.component('i-upload', require('./components/i-upload.vue').default)
app.component('i-images', require('./components/i-images.vue').default)
app.component('i-contents', require('./components/i-contents.vue').default)
app.component('i-page', require('./components/i-page.vue').default)
app.component('i-filter', require('./components/i-filter.vue').default)
app.component('power-switch', require('./components/power-switch.vue').default)
app.component('quick-edit', require('./components/quick-edit.vue').default)
app.component('i-image', require('./components/i-image.vue').default)
app.component('i-logs', require('./components/i-logs.vue').default)
app.component('i-import', require('./components/i-import.vue').default)

app.component('folder-node', require('./components/folder-node.vue').default)

/** Bind app */
app.mount('#app')
