import {isArray, isObject} from "ispa-element/lib/utils/util";
import {ElNotification} from "element-plus";
import numeral from "numeral";
import moment from "moment-timezone";
import useClipboard from 'vue-clipboard3'

export const getLanguageName = (data = [], id = 0) => {
  const lang = data.find(e => e.id === id)
  if (!lang || !lang.name) {
    return 'N/A'
  }
  return lang.name
}

/** Make copy */
export const iCopy = async val => {
  const {toClipboard} = useClipboard()
  try {
    if (val) {
      await toClipboard(val)
      iAlert({title: 'Success!', message: `<b>${val}</b> has been copied!`, type: 'success'})
    }
  } catch (e) {
    iAlert(t('alert.error.copy'), true)
  }
}

/** Notification Alert */
export const iAlert = (val = [], error = false) => {
  const notify = {
    type: 'success',
    message: null,
    dangerouslyUseHTMLString: true,
    position: 'bottom-left'
  }
  if (isArray(val)) {
    notify.message = val[0]
    if (val[1]) notify.title = val[1]
    if (val[2]) notify.type = val[2]
  } else if (isObject(val)) {
    if (val.title) {
      notify.title = val.title
    }
    if (val.message) {
      if (isArray(val.message) || isObject(val.message)) {
        let str = ''
        if (isArray(val.message)) {
          val.message.forEach(e => {
            if (isArray(e)) {
              e.forEach(f => {
                str += `<p>${f}</p>`
              })
            } else {
              str += `<p>${e}</p>`
            }
          })
        } else {
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(val.message)) {
            if (isArray(value)) {
              value.forEach(f => {
                str += `<p>${f}</p>`
              })
            } else {
              str += `<p>${value}</p>`
            }
          }
        }
        notify.message = str
      } else {
        notify.message = val.message
      }
    }
    if (val.type) {
      notify.type = val.type
    }
    if (val.error) {
      notify.type = 'error'
    }
  } else {
    notify.message = val
  }
  if (val.title) {
    notify.title = val.title
  }
  //  Over write error status
  if (error) {
    notify.type = 'error'
  }
  ElNotification(notify);

  if (notify.message.includes('401')) {
    window.location.href = '/'
  }
}

/** Number format */
export const numberFormat = (val, format = '0,000.0') => {
  return numeral(val).format('"' + format + '"');
}

/** Date format */
export const dateFormat = (val = null, format = 'YYYY-MM-DD') => {
  if (!val) {val = new Date()}
  let type = 'date'

  return moment(val).tz('Asia/Ho_Chi_Minh').format(format);
}

/**
 * Get class color for badge by permission key
 *
 * @param val
 * @returns {string}
 *
 * @author malayvuong
 * @since 1.0.0
 */
export const badgeColor = val => {
  let className = 'default';
  switch (val) {
    case 'viewAny':
    case 'create':
    case 'view':
      className = 'success';
      break;
    case 'edit':
    case 'update':
      className = 'primary';
      break;
    case 'delete':
    case 'forceDelete':
    case 'banned':
      className = 'danger';
      break;
    case 'restore':
      className = 'info';
      break;
    case 'username':
    case 'email':
    case 'mobile':
      className = 'warning';
      break;

    default:
      break;
  }
  return className;
}



export default {
  iAlert, numberFormat, dateFormat, badgeColor, iCopy, getLanguageName,
}
