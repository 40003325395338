import ajax from '@/ajax'
import {reactive, toRefs} from "vue";
import {iAlert} from '@/mixins/utils'

/**
 *
 * Todo: old code, need update
 *
 * */

const state = reactive({
  upTable: 'Upload', upLoading: false, upList: {data: []}, upItem: {}, upLimit: 20, upPage: 1,
  upQuery: null, upTotal: 0, upf: {},
  //  Set table width on Desktop
  width: {
    //  Fixed width
    main: 0, power: 40, image: 50, featured: 70, priority: 90,
    // Must change
    /** 1 icon = 55 */ operation: 165, category: 200, publishedAt: 100, language: 110,
  }
})

const useUploadMixins = () => {

  /** Set upload list from data */
  const upSet = (data) => {
    state.upList.data = data
  }
  /** Delete Upload file */
  const upDel = (id = 0, list = []) => {
    if (id > 0) {
      state.upLoading = true
      const index = list.findIndex(e => e.id === id)
      if (index > -1) {
        ajax.delete(`${state.upTable.toLowerCase()}/${id}`).then(res => {
          if (!res.data.error) {
            list.splice(index, 1)
          }
          if (res.data.message) {
            iAlert(res.data)
          }
          state.upLoading = false
        }).catch(err => {
          iAlert(err, true)
          state.upLoading = false
        })
      }
    }
  }

  return {
    ...toRefs(state),
    upDel, upSet,
  };
};

export default useUploadMixins
