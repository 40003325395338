<template>
    <div class="block md:flex-1 md:flex md:flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div class="mx-auto w-full max-w-sm lg:w-96">
            <div>
                <a :href="urlBase" target="_blank" :title="$t('brand')">
                    <img class="h-12 w-auto mx-auto" :src="logoAuthUrl" :alt="$t('brand')">
                </a>
            </div>


            <div>
                <h2 class="mt-6 text-3xl font-extrabold text-gray-900">
                    {{ $t('nav.b.sign-in') }} Admin
                </h2>
            </div>

            <div class="mt-8">
                <div class="mt-6 space-y-6">
                    <i-form-item :label="$t('validation.attributes.username')" for="i-username">
                        <i-input id="i-username" v-model="form.username" name="username" type="text" icon="fad fa-fingerprint" :placeholder="$t('validation.attributes.username')" />
                    </i-form-item>
                    <i-form-item :label="$t('validation.attributes.password')" for="i-password">
                        <i-input id="i-password" v-model="form.password" name="password" type="password" icon="fad fa-lock-alt" :placeholder="$t('validation.attributes.password')" />
                    </i-form-item>
                    <div class="flex items-center justify-between">
                        <router-link :to="{name: 'auth-forgot'}" class="text-sm text-gray-500 hover:text-gray-800">
                            {{ $t('nav.a.forgot') }}
                        </router-link>
                        <!--      <router-link :to="{name: 'auth-register'}" class="ml-auto text-sm text-gray-500 hover:text-gray-800">-->
                        <!--        <i class="fad fa-user-plus"></i>-->
                        <!--        {{ $t('nav.b.register') }}-->
                        <!--      </router-link>-->
                    </div>
                    <div>
                        <i-button :loading="loading" @click="submitForm" block type="primary" icon="fad fa-sign-in">{{ $t('nav.b.sign-in') }}</i-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, nextTick, onMounted, ref} from 'vue'
import Cookies from 'js-cookie'
import ajax from '@/ajax'
import cookiesConfig from '@/config/cookies'
import {useRouter} from 'vue-router'
import {iAlert} from '@/mixins/utils'
import {useI18n} from "vue-i18n";
import {urlBase, logoAuthUrl} from "@/config/base";

/** Empty form to reset form */
const emptyForm = {
    username: null,
    password: null,
    remember: false,
}

export default defineComponent({
    name: 'SignIn',
    setup(props) {
        const {t} = useI18n()
        const form = ref(emptyForm)
        const loading = ref(false)
        const router = useRouter()
        /** Process to submit form */
        const submitForm = () => {
            if (form.value.username && form.value.password) {
                //  Begin loading
                loading.value = true
                //  Submit form
                ajax.post(`auth/sign-in`, form.value).then(res => {
                    if (!res.data.error) {
                        const user = res.data.data
                        //  If remember has been checked => set cookies expires 1 days
                        if (form.value.remember) {
                            Object.assign(cookiesConfig, {
                                expires: 1
                            })
                        }
                        //  Store token to Cookie
                        if (user.token) {
                            Cookies.set('token', user.token || null, cookiesConfig)
                        }
                        if (user.user) {
                            Cookies.set('user', user.user || null, cookiesConfig)
                        }
                        if (user.locale) {
                            Cookies.set('locale', user.locale || 'vi', cookiesConfig)
                        }
                        if (user.languages) {
                            Cookies.set('app-languages', user.languages || [], cookiesConfig)
                        }
                        /** Set role */
                        Cookies.set('roles', (user.roles || null), cookiesConfig)
                        /** reSet form */
                        form.value = emptyForm
                        //  Push router return to dashboard => reload page to load new cookies
                        nextTick(() => {
                            router.go(router.currentRoute)
                        })
                    }
                    if (res.data.message) {
                        iAlert(res.data)
                    }
                    loading.value = false
                }).catch(err => {
                    iAlert(err, true)
                    loading.value = false
                })
            } else {
                iAlert(t('msg.error.fields-empty'), true)
            }
        }

        onMounted(() => {
            if (Cookies.get('token') !== undefined) {
                router.push({
                    name: 'my-dashboard'
                })
            }
        })

        return {
            form, urlBase, logoAuthUrl,
            submitForm,
            loading
        }
    }
})
</script>
