import {reactive, ref, toRefs} from 'vue'
import Cookies from 'js-cookie'
import ajax from './ajax'
import cookiesConfig from "@/config/cookies";

const state = reactive({
  loading: false,
  rules: null,
  token: Cookies.get('token') || undefined,
  user: Cookies.get('user') || undefined,
  roles: Cookies.get('roles') !== undefined ? JSON.parse(Cookies.get('roles')) : [],
  languages: Cookies.get('app-languages') !== undefined ? JSON.parse(Cookies.get('app-languages')) : [],
  locale: Cookies.get('locale') || 'vi',
  myEvent: {},
  error: 'Loading...',
  devices: null,
});

/**
 * UseState
 *-------------------
 * @author malayvuong
 *
 **/
const useState = () => {
  /** Get user level */
  const getLevel = () => {
    let level = 99
    if (state.roles) {
      state.roles.forEach(e => {
        if (e.custom.level && parseInt(e.custom.level) < level ) {
          level = parseInt(e.custom.level)
        }
      })
    }
    return level
  }
  /** Remove cookie */
  const removeCookies = () => {
    Cookies.remove('token', cookiesConfig)
    Cookies.remove('user', cookiesConfig)
    Cookies.remove('role', cookiesConfig)
    Cookies.remove('app-languages', cookiesConfig)
    Cookies.remove('locale', cookiesConfig)
  }
  /** Check user Rule */
  const rule = keyName => {
    //  if not key name => return true
    if (!keyName) return true
    //  set default value
    let $return = false
    if (!state.roles || state.roles.length < 1 || !keyName || !state.user) return $return
    state.roles.forEach(role => {
      const index = role.permissions.findIndex(e => e.group.concat('.', e.name) === keyName)
      if (index >= 0) {
        $return = true
      }
    });
    return $return
  }
  /** Get user info */
  const fetchUser = () => {
    if (state.token !== undefined && state.token !== null) {
      state.loading = true;

      ajax.get(`account/info`).then(res => {
        if (!res.data.error) {
          const user = res.data.data
          state.user = user || null;
          if (user.roles) {
            state.roles = user.roles || []
          }

          if (user.devices) {
            state.devices = user.devices || []
          }
          //  Reset error
          state.error = null
        } else {
          state.error = 'User information has not valid.'
        }
        state.loading = false;
      }).catch(err => {
        state.loading = false;
        state.error = 'Cannot connect to server...'
        if (String(err).includes('401')) {
          //  Remove all cookies data
          removeCookies()
          //  Reload page
          window.location.reload()
        }
      });
    } else {
      state.error = 'No token found. Redirect to homepage...'
    }
  };

  return {
    ...toRefs(state), // convert to refs when returning
    fetchUser, rule, removeCookies
  };
};

export default useState
