import ajax from '@/ajax'
import {iAlert} from './utils'
import FormClass from '@/mixins/formClass'

export default class AbstractClass {
  /** Model name of tb (User, News, Events...) */
  tb = null
  url = null
  //  Router config
  r = {
    /** @var c Create page */
    c: null,
    /** @var u Update page */
    u: null,
    /** @var m Management page */
    m: null,
    /** @var r Restore page */
    r: null,
    /** @var d View detail an object */
    d: null,
    /** @var v View detail with report an object */
    v: null,
  }
  //  Main configuration
  cf = {
    //  Form config
    f: {}
  }
  /** Inner f */
  __f = new FormClass(this.tb)

  timeOut = 1500 // millisecond
  /** ld status */
  ld = false
  /** Reload status */
  rl = false;
  rf = false;
  /** l return from api */
  l = {data: [], search: [], menu: []};
  /** Single item object */
  i = {};
  /** Number of display limited per page */
  lm = 30;
  /** Current page */
  p = 1;
  /** Keywords to search */
  q = '';
  fq = null;  //  Filter query => Sử dụng phương thức filter query này không có tính mở rộng trong tương lai => cần điều chỉnh
  /**
  /** Category ID & Event ID to filter */
  filter = [];
  categoryId = '';
  eventId = '';
  storagesId = '';
  enumerateId = '';
  //  Limit options list
  lmOption = [
    {value: 10, text: '10'},
    {value: 30, text: '30'},
    {value: 50, text: '50'},
    {value: 100, text: '100'},
    {value: 200, text: '200'},
  ]
  /** Type to filter */
  type = '';
  /** Total item on database */
  tt = 0
  /** Form object default */
  f = {}
  /** Column width of table */
  w = {
    //  Fixed width
    main: 0, pw: 40, img: 50, sl: 30 /** Select box */,
    f: 70,  //  Featured
    p: 90,  //  Priority
    l: 120, //  Language
    t: 150, //  Type name | Category name
    // Operation
    /** 1 icon = 55 */ op: 110,
  }
  //  Vue Router instance object (vue-router)
  router = null
  /** @var isExport - allow Export button */
  isExport = false;
  /** @var action - action selection for multiple update */
  action = [];
  /** list of selected - used for multi delete or update */
  multiSelect = []
  /** Mass update object */
  mu = {
    col: null,  //  Column to update
    value: null,  //  Value to update
    selected_id: [], //  List selected Id will be update
    type: null, //  Type of value to update (boolean, numeric, string, json, date, dateTime)
  }
  /** Export config */
  ep = {
    title: null,
    header: [],
    bgColor: '4ADE80',
    name: null,
    type: 'xlsx', //(xlsx || csv)
  };
  ip = {
    data: []
  }
  /** Todo: join modal to one object */
  /** @var modal - form modal open status */
  modal = false;
  /** @var expModal - Export modal open status */
  expModal = false;
  /** @var quickModal - Multiple update modal open status */
  multiModal = false;

  constructor(table = null, config = {}) {
    this.tb = table
    //  Set url
    this.url = table.toLowerCase()

    if (config) {
      //  Set router
      if (config.r) this.r = Object.assign(this.r, config.r)
      //  Set reload/re-fetch status
      if (config.rl) this.rl = config.rl  //  Use for reload list (modal edit/create)
      if (config.rf) this.rf = config.rf  //  Use for edit page (not redirect)
      //  Set url fetch
      if (config.url) this.url = config.url
    }
  }

  /**
   *  Multiple update request
   *
   * @author pham anh phien
   * @since 1.0.0
   */
  quickUpdateMulti()
  {
    this.mu.selected_id = [] //  Reset selected list

    this.multiSelect.forEach(e => this.mu.selected_id.push(e.id))

    ajax.put(`${this.url}/multi-update`, this.mu).then(res => {
      if (!res.data.error) {
        this.gets()
        this.multiModal = false
      }
      if (res.data.message) {
        iAlert(res.data)
      }
      this.ld = false
    }).catch((err) => {
      iAlert(err, true)
      this.ld = false

    })
  }
  /**
   * Public searching
   *
   * @author malayvuong
   * @since 1.0.0
   */
  publicSearch() {
    ajax.get('search/' + this.url, {params: this.params()}).then(res => {
      if (!res.data.error) {
        this.l = res.data.data
      }
      if (res.data.message) {
        iAlert(res.data)
      }
    }).catch(err => {
      iAlert(err, true)
    })
  }
  /**
   * Search by query (for filter)
   *
   * @author malayvuong
   * @since 1.0.0
   */
  search(query = null) {
    if (!query || query.length < 3) {
      this.l.search = []
      return false;
    }
    this.ld = true
    ajax.get(this.url + '/search', {params: {query: query}}).then(res => {
      if (!res.data.error) {
        this.l.search = res.data.data
      }
      if (res.data.message) {
        iAlert(res.data)
      }
      this.ld = false
    }).catch(err => {
      iAlert(err, true)
      this.ld = false
    })
  }

  /**
   * get all
   *-------------------
   * @param lm | change limit per page (can dynamic set)
   * @author phamanhphien
   * @since 1.0.0
   **/
  gets(lm = 0) {
    if (lm > 0) this.lm = lm
    this.ld = true
    ajax.get(this.url, {params: this.params()}).then(res => {
      if (!res.data.error) {
        this.l = res.data.data
        //  Set total
        if (res.data.data.total) {
          this.tt = res.data.data.total
        }
      }
      this.ld = false
    }).catch(err => {
      iAlert(err, true)
      this.ld = false
    })
  }

  /**
   * Generate params for gets()
   *-------------------
   * @return Object
   * @author phamanhphien
   * @since 1.0.0
   **/
  params() {
    let p = {page: this.p, q: this.q, limit: this.lm, filterQuery: this.fq}
    if(this.categoryId || this.categoryId.length > 0)
      p.category = this.categoryId
    if(this.storagesId || this.storagesId.length > 0)
      p.storagesId = this.storagesId
    if(this.eventId || this.eventId.length > 0)
      p.event = this.eventId
    if(this.type && this.type.length > 0)
      p.type = this.type

    return p
  }

  /**
   * Get item by id
   *-------------------
   * @param {Number|String} id | number of id
   * @param {String} edit | edit link suffix ('/edit')
   * @param callback | callback
   * @author phamanhphien
   * @editor malayvuong
   * @since 1.0.0
   **/
  async get(id, edit = null, callback = null)  {
    if (!id || id === 0) {
      return false;
    }
    this.ld = true
    await ajax.get(`${this.url}/${id}${edit || ''}`).then(res => {
      if (!res.data.error) {
        //  If edit, set f for edit
        if (edit) {
          this.setForm(res.data.data, callback)
        } else {
          this.i = res.data.data
        }
      }
      if (res.data.message) {
        iAlert(res.data)
      }
      this.ld = false
    }).catch(err => {
      iAlert(err, true)
      this.ld = false
    })
  }

  /**
   * Get list for menu
   *
   * @author malayvuong
   * @since 1.0.0
   */
  getMenu() {
    this.ld = true
    ajax.get(`${this.url}/menu`, {params: this.params()}).then(res => {
      if (!res.data.error) {
        this.l.menu = res.data.data.data
      }
      if (res.data.message) {
        iAlert(res.data)
      }
      this.ld = false
    }).catch(err => {
      this.ld = false
      iAlert(err, true)
    })
  }

  /**
   * Get by Uid
   *
   * @author malayvuong
   * @since 1.0.0
   */
  publicGetUid(uid = null) {
    if (uid !== null && uid.length > 0) {
      this.ld = true
      ajax.get(`search/${this.url}/uid/${uid}`).then(res => {
        if (!res.data.error) {
          this.i = res.data.data
        }
        if (res.data.message) {
          iAlert(res.data)
        }
        this.ld = false
      }).catch(err => {
        iAlert(err, true)
        this.ld = false
      })
    }
  }

  /**
   * set form = val
   *-------------------
   * @author phamanhphien
   * @since 1.0.0
   **/
  setForm(val, callback = null)  {
    this.resetForm()
    if (val) {
      this.f = Object.assign(this.f, val)

      if (this.f.status_id === null) {
        this.f.status_id = 1
      }

      if (val.status_type === null) {
        this.f.status_type = 'accepted'
      }

      if (val.categories && val.categories.length > 0) {
        let list = []
        val.categories.forEach(e => {
          if (e && e.id && e.id > 0) {
            list.push(e.id)
          }
        })
        this.f.cates = list
      }

      //  Re-define config & custom object
      this.f.config = Object.assign(this.f.config || {}, val.config || {})
      this.f.custom = Object.assign(this.f.custom || {}, val.custom || {})

      if (val.slug && val.slug.target_id > 0) {
        this.f.slug = val.slug.slug
      }

      //  Parse meta data
      if (val.metadata) {
        let parse = {}
        val.metadata.forEach(e => {
          if (e.name === 'gender') {
            parse[e.name] = parseInt(e.value)
          } else {
            parse[e.name] = e.value
          }
        })

        this.f.meta = parse
      }
      //  Parse role
      if (val.roles) {
        let roles = []
        val.roles.forEach(e => {
          roles.push(e.id)
        })
        this.f.roles = roles
      }
      //  Process to callback or open modal
      if (this.modal !== true || (callback === null || callback === undefined)) {
        this.modal = true
      } else {
        callback()
      }
    }
  }

  /**
   * reset form
   *-------------------
   * @author phamanhphien
   * @since 1.0.0
   **/
  resetForm() {
    this.f = new this.__f(this.tb)
  }

  /**
   * Reset to blank list
   *-------------------
   **@author malayvuong
   */
  resetList() {
    if (this.l.data) {
      this.l = {data: []}
    } else {
      this.l = []
    }
  }

  /**
   * delete by id
   *-------------------
   * @author phamanhphien
   * @since 1.0.0
   **/
  del(id){
    if (id > 0) {
      ajax.delete(`${this.url}/${id}`).then(res => {
        if (!res.data.error) {
          if(this.l.data){
            const index = this.l.data.findIndex(e => e.id === id)
            this.l.data.splice(index,1)
          }else {
            const index = this.l.findIndex(e => e.id === id)
            this.l.splice(index, 1)
          }
        }
        if (res.data.message) {
          iAlert(res.data)
        }
      }).catch(err => {
        iAlert(err, true)
      })
    }
  }

  /**
   * Update or insert
   *
   * @author phamanhphien
   * @since 1.0.0
   **/
  submit(editable = null) {
    const f = this.f
    if (f) {
      if (f.location && f.location.length === 0) {
        return iAlert(t('alert.error.eventsLocation'), true)
      }
      this.ld = true
      if(this.type){
        this.f.type = this.type
      }
      if (this.modal) {
        this.modal = false
      }
      if (this.expModal) {
        this.expModal = false
      }
      if (f.id < 1) {
        ajax.post(this.url, f).then(res => {
          if (!res.data.error) {
            this.resetForm()
            // Redirect to manage page
            if (this.r.m) {
              this.iGo(this.r.m, this.timeOut)
            }

            if (this.rl) {
              this.gets()
            }
            if (editable !== null) {
              editable = false
            }
          }
          if (res.data.message) {
            iAlert(res.data)
          }
          this.ld  = false
        }).catch(err => {
          iAlert(err,true)
          this.ld  = false
        })
      } else {
        if(f.logo){
          f.logo = f.logo.filter(e => e !== null);
        }
        ajax.put(`${this.url}/${f.id}`, f).then(res => {
          if (!res.data.error) {
            // Redirect to manage page
            if(this.r.m) {
              this.iGo(this.r.m, this.timeOut)
            }

            if (this.rl) {
              this.resetForm();
              this.gets();
            }
            if (this.rf) {
              this.get(this.f.id, '/edit');
            }
          }
          if (res.data.message) {
            iAlert(res.data)
          }
          this.ld  = false
        }).catch(err => {
          iAlert(err, true)
          this.ld  = false
        })
      }
    } else {
      iAlert(t('msg.error.fields-empty'), true)
    }
  }

  /**
   * Quick Update element
   * * Value can be null (for text edit)
   * * Call gets() function for fallback or error
   *
   * @param {Number} id | Id to update
   * @param {String} col | Column to update (camelCase)
   * @param val | value to update
   *
   * @author phamanhphien
   * @editor malayvuong
   * @since 1.0.0
   **/
  quickUpdate(id = 0, col = null, val = null) {
    if (id > 0 && col) {
      this.ld = true
      ajax.put(`${this.url}/${id}/update`, {col: col, value: val}).then(res => {
        if (res.data.error) {
          //  Call callback function or update all
          if (this.type === 'Category') {
            this.getTree()
          } else {
            this.gets()
          }
        }

        if (res.data.message) {
          iAlert(res.data)
        }
        this.ld = false
      }).catch((err) => {
        iAlert(err, true)
        this.ld = false
        //  Call callback function or update all
        if (this.type === 'Category') {
          this.getTree()
        } else {
          this.gets()
        }
      })
    } else {
      console.log('Developer Error: Please provide ID and Column to update')
    }
  }

  /**
   * Delete multiple Value
   *-------------------
   * @author phamanhphien
   * @since 1.0.0
   **/
  delMulti() {
    this.ld = true
    ajax.put(`${this.url}/delete-multi`,{listId: this.multiSelect}).then(res => {
      if (!res.data.error) {
        res.data.data.forEach(id => {
          const index = this.l.data.findIndex((e) => e.id === id);
          if (index >= 0)
            this.l.data.splice(index,1)
        });
      }
      if (res.data.message) iAlert(res.data)
      this.ld = false
    }).catch(err => {
      this.ld = false
      iAlert(err, true)
    });
  }

  /** Redirect to router name */
  iGo (name = null, timeOut = 1500) {
    if (this.router && name) {
      setTimeout(() => {
        this.router.push({name: name})
      }, timeOut)
    }
  }

  /**
   * Get data like Folder Tree
   *
   * @author malayvuong
   * @since 1.0.0
   */
  getTree ()  {
    this.ld = true
    ajax.get(`${this.url}/new-tree`, {params: {type: this.type}}).then(res => {
      if (!res.data.error) {
        this.l = res.data.data
      }
      if (res.data.message) {
        iAlert(res.data)
      }
      this.ld = false
    }).catch(err => {
      iAlert(err, true)
      this.ld = false
    })
  }

  /** Table selection change */
  handleSelectionChange(val) {
    this.multiSelect = val
  }

  /** Mass update from multi select */
  massUpdate() {
    if (this.multiSelect.length > 0 && this.mu.status_id > 0) {
      this.ld = true
      ajax.post(`${this.url}mass-update`, {type: 'status_id', value: this.mu.status_id, data: this.multiSelect}).then(res => {
        if (!res.data.error) {
          if (this.rl) {
            this.gets()
          }
        }
        if (res.data.message) {
          iAlert(res.data)
        }
        this.ld = false
      }).catch(err => {
        iAlert(err, true)
        this.ld = false
      })
    }
  }

  /** Mass Delete */
  massDelete() {

  }
  /** Convert image base64**/
  base64Image  (img, type, quality) {
    const canvas = document.createElement("canvas");

    const MAX_WIDTH = 500;
    const MAX_HEIGHT = 500;

    let width = img.width;
    let height = img.height;
    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }
    canvas.width = width * quality;
    canvas.height = height * quality;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0,  width * quality , height * quality);
    return canvas.toDataURL("image/" + type);
  }

  /** Export image to excel**/
  getImage(val, row, col, workbook, worksheet, quality = 5)  {
    const encode =  this.base64Image(val, 'png', quality)
    const img = workbook.addImage({
      base64: encode,
      extension: 'png',
    });
    worksheet.addImage(img, {
        tl: {col: col, row: 3 + row},
        ext: {width: 60, height: 60},
        editAs: 'oneCell'
      }
    );
  }

  //  Set row export data
  setData(workbook, worksheet, checked, header) {
    console.log(this.multiSelect, workbook, worksheet, checked, header)
    for (let i = 0; i < this.multiSelect.length; i++) {
      let newItem = {}
      checked.forEach(e => {
        if (['image', 'qrcode'].includes(e)) {
          if (e === 'image') {
            //  Begin import image
            let image = (document.getElementById('export_img_' + this.multiSelect[i].id))
            if (image && image.src) {
              this.getImage(image, i, header.length -2, workbook, worksheet, 5)
            }
          } else {
            //  Begin import QR Code
            const spc = this.multiSelect[i]
            let qr = (document.getElementById('qr-'+spc.id+'-'+spc.uid).getElementsByTagName('img')[0])
            if (qr) {
              this.getImage(qr, i, header.length -1, workbook, worksheet, 5)
            }
          }
        } else {
          if(e.indexOf('.') > 0) {
            const exp = e.split('.')
            const item = this.multiSelect[i][exp[0]].find(f => f.key_name === exp[1])
            if (item) {
              newItem[e] = item.value
            }
          } else {
            newItem[e] = this.multiSelect[i][e]
          }
        }
      })
      //  Add new row
      const row = worksheet.addRow(newItem);
      //  Set custom Row height
      if (newItem.qrcode !== null || newItem.image !== null) {
        row.height = 40
        row.alignment = {vertical: 'middle'}
      }
    }
  }

  import() {
    if (this.ip.data.length > 0) {
      this.ld = true
      ajax.post(`${this.url}/import`, this.ip).then(res => {
        if (!res.data.error) {
          this.ip.data = []
        }
        if (res.data.message) {
          iAlert(res.data)
        }
        this.ld = false
      }).catch(err => {
        iAlert(err, true)
        this.ld = false
      })
    } else {
      return ''
    }
  }

  /** Get enumerate name */
  getEnumName(val) {
    const item = this.l.data.find(e => (e.key_name === val || val.includes('.' + e.key_name)))

    return item ? item.name : ''
  }
}
