import {absState, action, mutation} from './modulesAbstract'

const table = 'Species'

absState.set(table)
const data = JSON.parse(window.localStorage.getItem('iSpa-' + table) || '[]')

const state = absState
state.data = data.data || []

const getters = {

}

const actions = {
  ...action
}


const mutations = {
  ...mutation
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
