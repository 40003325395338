import { createLogger } from 'vuex'

import {KEY_NAME} from '@/vuex/config'

const localStoragePlugin = store => {
  store.subscribe((mutation, state) => {
    KEY_NAME.forEach(e => {
      const item = state[e.toLowerCase()]
      if (item.length > 0) {
        window.localStorage.setItem('iSpa-' + e, JSON.stringify(item || []))
      }
    })
  })
}

export default process.env.NODE_ENV !== 'production'
  ? [createLogger(), localStoragePlugin]
  : [localStoragePlugin]
