export default class FormClass  {
  id = 0
  uuid = null
  name = null
  slug = ''
  slugTable = null
  description = null
  content = [{type: 'text', value: null}]
  language_id = null
  hashtag = []
  images = []
  image = {}
  status_id = 1

  constructor(slugTable = null) {
    this.slugTable = slugTable;
  }
}
