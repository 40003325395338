import {absState, action, mutation} from './modulesAbstract'

const table = 'Enumerate'

absState.set(table)
const data = JSON.parse(window.localStorage.getItem('iSpa-' + table) || '[]')

const state = absState
state.data = data.data || []

const getters = {
  filterByType(state, type = 'status') {
    return state.data.filter(e => e.type === type)
  }
}

const actions = {
  ...action
}


const mutations = {
  ...mutation
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
